<script>
import Button from '@/components/Button';
import InputComponent from '@/components/InputComponent';
import { mapState } from 'vuex';
import { getBonuses } from '@/helpers/api/shadow.api';

export default {
  name: 'Booster',
  components: { Button, InputComponent },
  data: () => ({
    multiplication: 0,
    stakeValue: 0,
    selectedTerm: null,
    termsList: {
      1: [
        { name: '30 days', value: 1 },
        { name: '180 days', value: 2 },
        { name: '1 year', value: 3 },
        // { name: '2 year', value: 4 },
        { name: '3 year', value: 5 },
        // { name: '4 year', value: 6 },
      ],
      4: [
        { name: '30-min', value: 1 },
        { name: '60-min', value: 2 },
      ],
      56: [
        { name: '30 days', value: 1 },
        { name: '180 days', value: 2 },
        { name: '1 year', value: 3 },
        // { name: '2 year', value: 4 },
        { name: '3 year', value: 5 },
        // { name: '4 year', value: 6 },
      ],
      97: [
        { name: '30-min', value: 1 },
        { name: '60-min', value: 2 },
      ],
    },
    userStakes: [],
    needApprove: false,
  }),
  computed: {
    ...mapState({
      milkBalance: (state) => state.Milk.milkBalance || 0,
      ethAddress: (state) => state.User.ethAddress,
      chainId: (state) => state.User.chainId,
    }),
    terms() {
      return this.termsList[this.chainId];
    },
    totalStaked() {
      let sum = this.$BN(0);
      this.userStakes.forEach((userStake) => {
        sum = sum.plus(userStake.amount);
      });
      return sum;
    },
  },
  watch: {
    async stakeValue(val) {
      if (val) this.needApprove = !(await this.checkApprove());
      else this.needApprove = false;
    },
  },
  created() {
    [this.selectedTerm] = this.terms;
    this.getBonuses();
    this.loadAllStakes();
    this.$root.$on('newBlock', this.newBlockHandler);
  },
  methods: {
    newBlockHandler() {
      this.getBonuses();
      this.loadAllStakes();
    },
    async getBonuses() {
      const bonuses = await getBonuses(this.ethAddress);
      this.multiplication = bonuses.actualMultiplication;
    },
    async updateStaking() {
      await this.loadAllStakes();
    },
    async loadAllStakes() {
      let tmpUserStakes = [];
      await Promise.all(
        this.terms.map(async (term) => {
          try {
            const contract = this.$contracts.getContract(`booster.${term.value}`);
            const getPersonalStakesResult = await contract.methods
              .getPersonalStakes(this.ethAddress)
              .call();
            let firstElement = true;
            getPersonalStakesResult[0].forEach((item, index) => {
              const seconds = Math.ceil(
                getPersonalStakesResult[0][index] - Math.round(Date.now() / 1000),
              );
              tmpUserStakes.push({
                seconds,
                days: Math.ceil(seconds / 60 / 60 / 24),
                amount: this.$contracts.fromWei(getPersonalStakesResult[1][index]),
                firstElement,
                term,
              });

              firstElement = false;
            });
          } catch (e) {
            console.error(e);
          }
        }),
      );

      tmpUserStakes = tmpUserStakes.sort((a, b) => {
        if (a.seconds > b.seconds) return 1;
        if (a.seconds < b.seconds) return -1;
        return 0;
      });

      this.userStakes = tmpUserStakes;
    },
    async checkApprove() {
      try {
        const stakeAmountFinally = this.$contracts.toWei(this.stakeValue);
        const approvedAmount = await this.$contracts
          .getContract('milk2')
          .methods.allowance(
            this.ethAddress,
            this.$contracts.getAddress(`booster.${this.selectedTerm.value}`),
          )
          .call();
        return this.$BN(approvedAmount).gte(stakeAmountFinally);
      } catch (e) {
        console.error('checkApprove() method error', e);
        return false;
      }
    },
    async stake() {
      try {
        const stakeAmountWei = this.$contracts.toWei(this.stakeValue);
        const stakePeriod = this.selectedTerm.value;
        if (!(await this.checkApprove())) {
          const approveResult = await this.$contracts
            .getContract('milk2')
            .methods.approve(
              this.$contracts.getAddress(`booster.${stakePeriod}`),
              this.$BN(2)
                .pow(256)
                .minus(1)
                .toString(),
            )
            .send({ from: this.ethAddress });

          if (typeof approveResult.status === 'undefined' || !approveResult.status) {
            console.error(approveResult);
            return false;
          }
        }

        const stakeResult = await this.$contracts
          .getContract(`booster.${stakePeriod}`)
          .methods.stake(stakeAmountWei.toString(), this.$web3.utils.fromAscii(''))
          .send({ from: this.ethAddress });

        if (typeof stakeResult.status === 'undefined' || !stakeResult.status) {
          console.error(stakeResult);
          return false;
        }

        await this.updateStaking();

        return true;
      } catch (e) {
        console.error('createStake() method error', e);
        return false;
      }
    },
    async unStake(unStakeValue, stakePeriod) {
      try {
        const unStakeValueWei = this.$contracts.toWei(unStakeValue).toString();

        const unstakeResult = await this.$contracts
          .getContract(`booster.${stakePeriod}`)
          .methods.unstake(unStakeValueWei, this.$web3.utils.fromAscii(''))
          .send({ from: this.ethAddress });

        if (typeof unstakeResult.status === 'undefined' || !unstakeResult.status) {
          console.error(unstakeResult);
          return false;
        }

        await this.updateStaking(false);

        return true;
      } catch (e) {
        console.error('unStake() method error', e);
        return false;
      }
    },
  },
};
</script>

<template>
  <v-col class="main-wrap main-wrap--narrow booster">
    <div class="page-content">
      <h1 class="page-title">{{ $t('booster.title') }}</h1>
      <div class="attention">
      <span class="attention__first">{{ $t('booster.attention_title') }}</span>
      <span v-html="$t('booster.attention')"></span>
      <span>{{ $t('booster.attention1') }}</span>
      <span>{{ $t('booster.attention2') }}</span>
      </div>
      <v-row>
        <v-col class="col-12 text-center mb-5">
          <Button href="https://docs.spaceswap.app/spaceswap/products/booster">
            {{ $t('booster.how-it-works') }}
          </Button>
        </v-col>
      </v-row>
      <v-row justify="center">
        <h1 class="booster__subline">{{ $t('booster.your-multiplier') }} x{{ multiplication }}</h1>
      </v-row>
      <v-row class="page-content" align="stretch">
        <v-col class="col-12 col-md-6">
          <div class="main-block">
            <h1 class="booster__header">{{ $t('booster.staking') }}</h1>
            <div class="main-block booster__inner-block">
              <div class="booster__topline-wrap">
                <div class="booster__topline-title">{{ $t('booster.stake-amount') }}</div>
                <div class="booster__topline-max">MAX {{ milkBalance | amount(4) }}</div>
              </div>
              <InputComponent :max="true" v-model="stakeValue" @click="stakeValue = milkBalance"/>
            </div>
            <div class="main-block booster__inner-block">
              <div class="booster__topline-wrap">
                <div class="booster__topline-title">{{ $t('booster.stake-period') }}</div>
              </div>

              <v-menu offset-y>
                <template v-slot:activator="{ on, attrs }">
                  <div v-bind="attrs" v-on="on" class="booster__terms">
                    <div>{{ selectedTerm.name }}</div>
                    <img
                      class="caret"
                      alt=""
                      :src="require(`@/assets/img/${$chain.getName()}/caret.svg`)"
                    />
                  </div>
                </template>
                <v-list>
                  <v-list-item v-for="term in terms" @click="selectedTerm = term" :key="term.value">
                    <span :class="{ active: selectedTerm.value === term.value }">
                      {{ term.name }}
                    </span>
                  </v-list-item>
                </v-list>
              </v-menu>
            </div>
            <Button @click="stake" class="btn__w100" disabled>
              {{ needApprove ? $t('booster.approve-and-stake') : $t('booster.stake') }}
            </Button>
          </div>
        </v-col>
        <v-col class="col-12 col-md-6">
          <div class="main-block">
            <div class="main-block booster__inner-block">
              <div class="booster__topline-wrap">
                <div class="booster__topline-title">{{ $t('booster.your-balance') }}</div>
              </div>
              <div class="booster__topline-wrap mt-5">
                <div class="booster__topline-max">{{ $t('booster.total-stacked') }}</div>
                <div class="booster__topline-title">{{ totalStaked | amount(4) }} MILK2</div>
              </div>
            </div>
            <div class="booster__subtitle">{{ $t('booster.staking-terms') }}</div>
            <template v-if="userStakes && userStakes.length">
              <div
                class="main-block booster__inner-block"
                v-for="(userStake, index) in userStakes"
                :key="index"
              >
                <div class="booster__topline-wrap">
                  <div class="booster__topline-title">
                    {{ userStake.term.name }}
                    <Button
                      v-if="userStake.firstElement && userStake.days <= 0"
                      @click="unStake(userStake.amount, userStake.term.value)"
                    >
                      {{ $t('booster.unstake') }}
                    </Button>
                    <Button v-else-if="!userStake.firstElement && userStake.days <= 0" disabled>
                      {{ $t('booster.unstake') }}
                    </Button>
                    <p v-else>Ends in {{ userStake.days }} days</p>
                  </div>
                  <div class="booster__topline-title">{{ userStake.amount | amount(4) }} MILK2</div>
                </div>
              </div>
            </template>
            <div class="main-block booster__inner-block" v-else>
              {{$t('booster.dont-staking')}}
            </div>
          </div>
        </v-col>
      </v-row>
      <v-row justify="center">
        <h1 class="booster__subline">{{$t('booster.multiplayer')}}</h1>
      </v-row>
      <div class="main-block multiplayer-table" v-if="!$chain.is('avalanche')">
        <div class="multiplayer-table__row--header">
          <div class="col">
            <span>MILK2</span>
          </div>
          <div class="col">
            <span>1 {{$t('booster.month')}}</span>
          </div>
          <div class="col">
            <span>6 {{$t('booster.months')}}</span>
          </div>
          <div class="col">
            <span>1 {{$t('booster.year')}}</span>
          </div>
          <div class="col">
            <span>3 {{$t('booster.years')}}</span>
          </div>
        </div>

        <div class="multiplayer-table__row">
          <div class="col">
            <span>1000</span>
          </div>
          <div class="col">
            <span>1,03</span>
          </div>
          <div class="col">
            <span>1,12</span>
          </div>
          <div class="col">
            <span>1,21</span>
          </div>
          <div class="col">
            <span>1,3</span>
          </div>
        </div>

        <div class="multiplayer-table__row">
          <div class="col">
            <span>10 000</span>
          </div>
          <div class="col">
            <span>1,1</span>
          </div>
          <div class="col">
            <span>1,3</span>
          </div>
          <div class="col">
            <span>1,7</span>
          </div>
          <div class="col">
            <span>2</span>
          </div>
        </div>

        <div class="multiplayer-table__row">
          <div class="col">
            <span>50 000</span>
          </div>
          <div class="col">
            <span>1,3</span>
          </div>
          <div class="col">
            <span>2</span>
          </div>
          <div class="col">
            <span>3</span>
          </div>
          <div class="col">
            <span>4,5</span>
          </div>
        </div>

        <div class="multiplayer-table__row">
          <div class="col">
            <span>100 000</span>
          </div>
          <div class="col">
            <span>1,5</span>
          </div>
          <div class="col">
            <span>2,5</span>
          </div>
          <div class="col">
            <span>4,5</span>
          </div>
          <div class="col">
            <span>8</span>
          </div>
        </div>
      </div>
      <!-- TODO: REMOVE AFTER AVALANCHE RELEASE -->
      <div class="main-block multiplayer-table" v-else>
        <div class="multiplayer-table__row--header">
          <div class="col">
            <span>MILK2</span>
          </div>
          <div class="col">
            <span>1 {{$t('booster.month')}}</span>
          </div>
          <div class="col">
            <span>6 {{$t('booster.months')}}</span>
          </div>
          <div class="col">
            <span>1 {{$t('booster.year')}}</span>
          </div>
          <div class="col">
            <span>3 {{$t('booster.years')}}</span>
          </div>
        </div>

        <div class="multiplayer-table__row">
          <div class="col">
            <span>-</span>
          </div>
          <div class="col">
            <span>-</span>
          </div>
          <div class="col">
            <span>-</span>
          </div>
          <div class="col">
            <span>-</span>
          </div>
          <div class="col">
            <span>-</span>
          </div>
        </div>

        <div class="multiplayer-table__row">
          <div class="col">
            <span>-</span>
          </div>
          <div class="col">
            <span>-</span>
          </div>
          <div class="col">
            <span>-</span>
          </div>
          <div class="col">
            <span>-</span>
          </div>
          <div class="col">
            <span>-</span>
          </div>
        </div>

        <div class="multiplayer-table__row">
          <div class="col">
            <span>-</span>
          </div>
          <div class="col">
            <span>-</span>
          </div>
          <div class="col">
            <span>-</span>
          </div>
          <div class="col">
            <span>-</span>
          </div>
          <div class="col">
            <span>-</span>
          </div>
        </div>

        <div class="multiplayer-table__row">
          <div class="col">
            <span>-</span>
          </div>
          <div class="col">
            <span>-</span>
          </div>
          <div class="col">
            <span>-</span>
          </div>
          <div class="col">
            <span>-</span>
          </div>
          <div class="col">
            <span>-</span>
          </div>
        </div>
      </div>
    </div>
  </v-col>
</template>

<style lang="scss">
.v-menu__content {
  box-shadow: 0 3px 3px -3px rgb(0 0 0 / 10%), 0 5px 5px 1px rgb(0 0 0 / 5%),
  0 3px 7px 2px rgb(0 0 0 / 5%);

  .v-list-item {
    cursor: pointer;

    span {
      font-size: 1.8rem;
      font-weight: 500;
      color: $main-font-color;

      &.active {
        font-weight: 700;
      }
    }

    &:hover {
      background: #f0f0f0;
    }
  }
}

.booster {
  &__header {
    font-size: 3.4rem;
    text-align: center;
    margin-top: 0;
  }

  &__subline {
    font-size: 2.8rem;
  }

  &__topline-wrap {
    display: flex;
    justify-content: space-between;
    align-items: center;
  }

  .main-block {
    padding: 4rem;
    height: 100%;
  }

  .btn__w100 {
    width: 100%;
  }

  &__terms {
    border: none;
    font-style: normal;
    font-weight: 600;
    font-size: 2.2rem;
    color: $main-font-color;
    display: flex;
    justify-content: space-between;

    body.binance & {
      color: $b-secondatry-font-color;
    }
  }

  &__inner-block {
    border-radius: 1.5rem;
    padding: 2rem !important;
    margin-bottom: 3rem;
    height: auto !important;

    &:last-child {
      margin-bottom: 0;
    }

    .btn {
      padding: 1.5rem 1.8em;
      font-size: 1.4rem;
      font-weight: 600;
      text-transform: capitalize;
      letter-spacing: 0.03rem;
      display: block;
      margin-top: 1rem;
    }

    .input-container {
      margin-bottom: 0;

      .input__wrapper {
        background: transparent !important;
        box-shadow: none !important;
        height: auto;
        margin-top: 1.5rem;

        input {
          padding-left: 0;
          padding-top: 0.5rem;
          padding-bottom: 0.5rem;
          width: calc(100% - 8rem);
          line-height: 4rem;
          height: auto;
        }

        .input-max {
          top: 0;
          right: 0;
          left: auto;
          border: none;

          .btn.btn--round {
            padding: 1.5rem 1.8em;
            font-size: 1.4rem;
            font-weight: 600;
            text-transform: uppercase;
            letter-spacing: 0.03rem;
            margin-top: 0;
          }
        }
      }
    }
  }

  &__topline-title {
    font-size: 1.4rem;
    font-weight: 600;
  }

  &__topline-max {
    font-size: 1.4rem;
    font-weight: 600;
    color: $grey-font-color;

    body.binance & {
      color: $b-secondatry-font-color;
    }
  }

  &__subtitle {
    font-size: 1.4rem;
    font-weight: 600;
    padding-left: 2rem;
    margin-bottom: 3rem;
  }
}

.booster {
  .multiplayer-table {
    width: 100%;
    overflow-y: scroll;
    margin-top: 4rem;

    &__row, &__row--header {
      min-width: 90rem;
      width: 100%;
      font-size: 2.2rem;
      display: flex;
      flex: 1;
      justify-content: space-between;
      text-align: center;

      .col {
        display: flex;
        justify-content: left;
        flex: 1;
        padding-left: 4rem;
        &:first-child {
          color: #A0A5D5 !important;
        }
      }
    }

    &__row {
      border-bottom: 2px dashed rgba(160, 165, 213, 0.3);
      padding: 0.5rem 0;
      font-weight: 600;
      color: #7478A0;
      &:last-child {
        border-bottom: none;
      }
    }

    &__row--header {
      font-weight: bold;
      padding-bottom: 2rem;
      border-bottom: 2px solid rgba(160, 165, 213, 0.2);
      .col {
        &:first-child {
          color: #FF7979 !important;
        }
      }
    }
  }
}

.attention {
  display: flex;
  justify-content: center;
  flex-direction: column;
  align-items: center;
  margin-bottom: 3rem;

  span {
    text-align: center;
    font-size:  1.8rem;
    font-weight: 400;

  }

  &__first {
    font-size:  2.4rem !important;
    margin-bottom: 0.5rem;
    font-weight: bold !important;
    color: #DEBE17;
  }
}
</style>
